import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import smoothscroll from 'smoothscroll-polyfill'

import Lightbox from './lightbox'

const SLIDE_INTERVAL = 4000

function getAverageRGB(imgEl) {

    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = {r:0,g:0,b:0},
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    height = canvas.height = 100;
    width = canvas.width = 100;

    context.drawImage(imgEl, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch(e) {
        /* security error, img on diff domain */
        return defaultRGB;
    }

    length = data.data.length;

    while ( (i += blockSize * 4) < length ) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i+1];
        rgb.b += data.data[i+2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r/count);
    rgb.g = ~~(rgb.g/count);
    rgb.b = ~~(rgb.b/count);

    return rgb;

}

function isElementInViewport(el) {
  let rect = el.getBoundingClientRect()

  let height = (window.innerHeight || document.documentElement.clientHeight)

  return (
    rect.top >= height * -1 &&
    rect.bottom <= height * 2
  )
}

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

class Projects extends Component {

  state = {
    lightbox: false,
    project: 0,
    list: [],
    variation: Math.random(),
    index: 0,
    innerHeight: {
      height: '100vh'
    }
  }

  direction = 1

  componentDidMount() {
    let list = shuffle(this.props.projects.slice(0))
    this.setState({ list }, () => this.initialise())
    window && smoothscroll.polyfill()
    document && document.querySelector('.projects').addEventListener('scroll', this.scrollDetect)
    document.addEventListener('keydown', this.onKeyDown)
    this.setProjectHeight()
    window && window.addEventListener('resize', this.setProjectHeight)
  }

  componentWillUnmount() {
    document && document.querySelector('.projects').removeEventListener('scroll', this.scrollDetect)
    document.removeEventListener('keydown', this.onKeyDown)
    window && window.removeEventListener('resize', this.setProjectHeight)
    clearTimeout(this.autoscroll)
  }

  onKeyDown = (e) => {
    if ( this.state.lightbox ) return e
    if (e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40) {
      return e
    }
    let el = document.querySelector('.projects')
    let direction = e.keyCode === 39 || e.keyCode === 40 ? 1 : -1
    let dist = el.scrollTop + (window.innerHeight * direction)
    if (dist >= el.scrollHeight || dist < 0) {
      return e
    }
    el.scrollTo({top: dist, behavior: 'smooth'})
    this.initAutoScroll()
  }

  setProjectHeight = () => {
    this.setState({ innerHeight: {
      height: window && window.innerHeight + 'px'
    }})
  }

  onScroll = (e) => {
    if ( this.state.lightbox ) return
    let index = document && parseInt((document.querySelector('.projects').scrollTop / window.innerHeight) + 0.01)
    this.setState({
      index: index, project: index,
    }, () => {
      this.initAutoScroll()
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 10);
  }

  start() {
    //console.log(`start() :: #project_${this.index}`)
    let { variation } = this.class_this.state
    let canvas = document.querySelector(`#project_${this.index}`)
    let ctx = canvas?.getContext("2d")
    let clientX = 1
    let clientY = 1
    let img = this
    let speed = 100

    if (!ctx) return

    if (typeof window !== undefined && window.innerWidth > 900) {
      let gi = 0
      let gl = this.gallery?.length
      const loadImage = () => {
        let li = new Image()
        li.onload = () => {
          if (!this.gallery[li.gi].desktop.color) {
            this.gallery[li.gi].desktop.color = getAverageRGB(li)
          }
          if (gi < gl) {
            loadImage()
          } else {
            let { list } = this.class_this.state
            list[this.index].acf.gallery = this.gallery
            this.class_this.setState({ list })
          }
        }
        li.gi = gi
        li.src = this.gallery[gi++].desktop.publicURL
      }
      if (gl) loadImage()
    }

    /*
    canvas.addEventListener("mousemove", (evt) => {
      if (this.class_this.state.project !== this.index && !lightbox) {
        this.class_this.setState({ project: this.index })
      }
      //clientX = evt.clientX / canvas.width
      //clientX = evt.clientX / canvas.width
      //clientY = evt.clientX / canvas.height
    })
    */

    function mix(a, b, l) {
      return a + (b - a) * l
    }

    function upDown(v) {
      return Math.sin(v) * 0.5 + variation
    }

    function render(time) {
      time *= 0.00035

      if (!isElementInViewport(canvas)) {
        return setTimeout(() => requestAnimationFrame(render), 100)
      }

      resize(canvas)

      let t1 = time
      let t2 = time * 0.37

      // for each line in the canvas
      for (let dstX = 0; dstX < canvas.width; ++dstX) {

        // v is value that goes 0 to 1 down the canvas
        let v = dstX / canvas.width

        // compute some amount to offset the src
        let off1 = Math.sin((v + clientX) * mix(30, 100, upDown(t1))) * speed
        let off2 = Math.sin((v + clientY) * mix(30, 100, upDown(t2)))
        let off = off1 + off2
        // off = 0

        // compute what line of the source image we want
        // NOTE: if off = 0 then it would just be stretching
        // the image down the canvas.
        let srcX = dstX * img.width / canvas.width + off

        if (srcX < 1) {
          srcX *= -1
        } else if (srcX > img.width - 1) {
          srcX = img.width-(srcX-img.width)
        }

        // draw a single line from the src to the canvas
        ctx.drawImage(
          img,
          // sx, sy, sw, sh
          srcX, 0, 1, img.height,
          // dx, dy, dw, dh
          dstX, 0, 1, canvas.height
        )
      }

      requestAnimationFrame(render)
    }
    requestAnimationFrame(render)

    function resize(canvas) {
      let width = canvas.clientWidth
      let height = canvas.clientHeight
      if (width !== canvas.width || height !== canvas.height) {
        canvas.width = width
        canvas.height = height
      }
    }
  }

  initialise = (lightbox = false) => {
    this.setState({ lightbox }, () => {
      this.state.list?.map((el, i) => {

        let options = {
          root: document.querySelector('.projects__items'),
          rootMargin: '0px',
          threshold: 0.5
        }

        let observer = new IntersectionObserver(() => {
          let img = new Image()
          img.index = i
          img.class_this = this
          img.gallery = el.acf?.gallery
          img.onload = this.start
          img.src = el.featuredImage?.node?.localFile?.publicURL
        }, options);

        let target = document.querySelector(`#project_${i}`)
        if (target) observer.observe(target)

      })
      this.initAutoScroll()
      this.direction = 1
    })

    document.body.classList.toggle('remove-overscroll', lightbox)
  }

  initAutoScroll = () => {
    clearTimeout(this.autoscroll)
    this.autoscroll = setTimeout(() => {
      let el = document.querySelector('.projects')
      let dist = el.scrollTop + (window.innerHeight * this.direction)
      if (dist >= el.scrollHeight || dist < 0) {
        this.direction *= -1
        dist = el.scrollTop + (window.innerHeight * this.direction)
      }
      el.scrollTo({top: dist, behavior: 'smooth'})
    }, SLIDE_INTERVAL)
  }

  openLightbox = (i) => {
    this.setState({ project: i, lightbox: true })
    clearTimeout(this.autoscroll)
    document.body.classList.toggle('remove-overscroll', true)
  }

  render() {
    let { list, project, lightbox, index, innerHeight } = this.state

    return (
      <>
        <section className='projects'>
          <div className='projects__items'>
            { list?.length > 0 && list.map((el, i) => (
              <div className='projects__item' style={innerHeight} key={i}>
                { !lightbox &&
                  <picture>
                    <canvas id={`project_${i}`} onClick={() => this.openLightbox(i)} />
                  </picture>
                }
              </div>
            )) }
          </div>
          <span
            className='projects__description'
            onMouseEnter={() => clearTimeout(this.autoscroll)}
            onMouseLeave={() => this.initAutoScroll()}
            onClick={() => this.openLightbox(index)}
          >
            { list?.length > 0 && list[index].acf.briefDescription }<span>(more&hellip;)</span>
          </span>
        </section>
        <Lightbox
          projects={list}
          index={project}
          active={lightbox}
          set={this.initialise}
        />
      </>
    )
  }
}

export default Projects
