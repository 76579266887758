import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import { TransitionState } from 'gatsby-plugin-transition-link'

import Header from '../components/header'
import Seo from '../components/seo'

import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
        <Header transitionStatus={this.props.transitionStatus} />
        { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'page'))  }
      </>
    )
  }
}

const PageWrapper = (props) => {
  return (
    <TransitionState>
      {({ transitionStatus }) => (
        <PageTemplate transitionStatus={transitionStatus} {...props} />
      )}
    </TransitionState>
  )
}


export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        components {
          ... on WpPage_Acf_Components_Projects {
            fieldGroupName
            projects {
              ... on WpProject {
                title
                content
                slug
                acf {
									gallery {
                    altText
                    desktop: localFile {
                      publicURL
                      childImageSharp {
                        original {
                          width
                          height
                        }
                        gatsbyImageData(
                          quality: 95
                          layout: CONSTRAINED
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                    }
                    mobile: localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(
                          quality: 95
                          layout: FULL_WIDTH
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                    }
                  }
                  homepageTitleColor
                  briefDescription
                  credits {
                    link
                    text
                  }
                  services {
                    text
                  }
                }
                featuredImage {
                  node {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Components_TitleContent {
            fieldGroupName
            content
          }
          ... on WpPage_Acf_Components_Contact {
            fieldGroupName
            title
            content
            columns {
              links {
                item {
                  text
                  link
                }
              }
            }
          }
          ... on WpPage_Acf_Components_Clients {
            fieldGroupName
            clients {
              logo {
                localFile {
                  publicURL
                }
                altText
              }
              link
            }
          }
          ... on WpPage_Acf_Components_TwoUpImage {
            fieldGroupName
            leftImage {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    quality: 95
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              altText
            }
            rightImage {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    quality: 95
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              altText
            }
          }
          ... on WpPage_Acf_Components_NextPage {
            fieldGroupName
            title
            rolloverText
            pageLink {
              ... on WpPage {
                id
                slug
                acf {
                  components {
                    ... on WpPage_Acf_Components_ImageBanner {
                      fieldGroupName
                      image {
                        altText
                        localFile {
                          publicURL
                          childImageSharp {
                            gatsbyImageData(
                              quality: 95
                              layout: FULL_WIDTH
                              formats: [AUTO, WEBP, AVIF]
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Components_Accordion {
            fieldGroupName
            items {
              title
              titleColour
              content
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      quality: 95
                      placeholder: DOMINANT_COLOR
                      layout: FULL_WIDTH
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              careers {
                title
                positions {
                  ... on WpJob {
                    title
                    slug
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Components_ImageBanner {
            fieldGroupName
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    quality: 95
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }

          ... on WpPage_Acf_Components_Careers {
            fieldGroupName
            title
            email
            positions {
              ... on WpJob {
                slug
                title
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
