import React, { Component } from 'react'
import Link from '../utils/link'

class Contact extends Component {

  render() {
    const { title, content, columns } = this.props

    return (
      <section className='title-content'>
        <div className='title-content__inner'>
          <div className='title-content__wrapper'>
            <div className='title-content__content' dangerouslySetInnerHTML={{ __html: content }} />
            <div className='contact'>
              { columns && columns.map((el, c) => (
                <div className='contact__columns' key={c}>
                  { el.links && el.links.map((line, l) => {
                    return (
                      <div className='contact__item' key={l}>
                        { line.item.link &&
                          <Link to={line.item.link} dangerouslySetInnerHTML={{ __html: line.item.text }} />
                        }
                        { !line.item.link &&
                          <span dangerouslySetInnerHTML={{ __html: line.item.text }} />
                        }
                      </div>
                    )
                  }) }
                </div>
              )) }
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Contact
