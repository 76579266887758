import React, { Component } from 'react'
import Link from '../utils/link'
import { navigate } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import ImageBanner from './image-banner'

class NextPage extends Component {

  state = {
    hover: false,
    clicked: false
  }

  mouseEnter = () => {
    let distance = window.innerHeight
    distance -= this.refs?.target?.getBoundingClientRect().bottom
    distance -= 40
    this.setState({ hover: distance * -1 })
  }

  render() {
    const { rolloverText, title, pageLink } = this.props

    // Pagelink must be set
    if (!pageLink?.length) {
      return null
    }

    let page = pageLink[0]

    let ibdata = page.acf.components.find(el => el.fieldGroupName === 'page_Acf_Components_ImageBanner')

    let { hover, clicked } = this.state

    const aniprops = {
      swipe: true,
      direction: "up",
      top: "exit",
      entryOffset: 100,
      duration: 0.6,
      to: `/${page.slug}/`,
      onMouseEnter: this.mouseEnter,
      onMouseLeave: () => {
        if ( clicked ) return
        setTimeout(() => {
          this.setState({ hover: 0 })
        }, 10)
      },
      onClick: (event) => {
        event.preventDefault()
        this.setState({ clicked: true})
        this.setState({ hover: window.innerHeight * -1 }, () => {
          setTimeout(() => {
            navigate(`/${page.slug}/`)
          }, 350);
        });

      },
    }

    return (
      <>
        <section className='next-page'>
          <div className='next-page__text'>

            <h3>
              <Link {...aniprops}>
                <span className='initial' ref='target'>{ title }</span>
                <span className='hover'>{ rolloverText || title }</span>
              </Link>
            </h3>


          </div>
        </section>
        { ibdata &&
          <div className='next-page__hover' style={{transform: `translate(0px, ${hover}px)`}}>
            <ImageBanner {...ibdata} />
          </div>
        }
      </>
    )
  }
}

export default NextPage
