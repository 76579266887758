import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class TwoUpImage extends Component {

  render() {
    const { leftImage, rightImage } = this.props

    return (
      <section className='title-content title-content--two-up'>
        <div className='title-content__inner'>
          <div className='title-content__images'>
            <div className='title-content__left-image'>
              <GatsbyImage image={getImage(leftImage?.localFile)} alt={`Visual Thing`} />
            </div>
            <div className='title-content__right-image'>
              <GatsbyImage image={getImage(rightImage?.localFile)} alt={`Visual Thing`} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TwoUpImage
