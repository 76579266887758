import React, { Component } from 'react'
import Link from '../utils/link'

class Careers extends Component {

  render() {
    const { title, email, positions } = this.props

    return (
      <section className='careers'>
        <div className='careers__inner'>
          <h5>{ title }</h5>
          <p><Link to={`mailto:${email}`}>{ email }</Link></p>
          { positions?.length > 0 &&
            <>
              <h6>Available positions</h6>
              <ul>
              { positions.map((position, i) => (
                <li key={i}>
                  <Link to={`/job/${position.slug}`}>
                    { position.title }
                    <span>(more...)</span>
                  </Link>
                </li>
              )) }
              </ul>
            </>
          }
        </div>
      </section>
    )
  }
}

export default Careers
