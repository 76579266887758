import React from 'react'

import Projects from '../components/projects'
import ImageBanner from '../components/image-banner'
import TitleContent from '../components/title-content'
import Clients from '../components/clients'
import Contact from '../components/contact'
import Accordion from '../components/accordion'
import TwoUpImage from '../components/two-up-image'
import NextPage from '../components/next-page'
import Careers from '../components/careers'

const renderBlock = (param, el, i, postType, func=null) => {
  param = param.replace(postType, '')
  let block = {
  	'_Acf_Components_Projects' : (el, i) => <Projects key={i} {...el} />,
    '_Acf_Components_ImageBanner' : (el, i) => <ImageBanner key={i} {...el} />,
    '_Acf_Components_TitleContent' : (el, i) => <TitleContent key={i} {...el} />,
    '_Acf_Components_Clients' : (el, i) => <Clients key={i} {...el} />,
    '_Acf_Components_Contact' : (el, i) => <Contact key={i} {...el} />,
    '_Acf_Components_Accordion' : (el, i) => <Accordion key={i} {...el} />,
    '_Acf_Components_TwoUpImage' : (el, i) => <TwoUpImage key={i} {...el} />,
    '_Acf_Components_NextPage' : (el, i) => <NextPage key={i} {...el} />,
    '_Acf_Components_Careers' : (el, i) => <Careers key={i} {...el} />,
  }[param]

  if (!block) return null

  return block(el, i)
}

export default renderBlock
