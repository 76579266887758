import React, { Component } from 'react'

class TitleContent extends Component {

  render() {
    const { content } = this.props

    return (
      <section className='title-content'>
        <div className='title-content__inner'>
          <div className='title-content__wrapper'>
            <div className='title-content__content' dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </section>
    )
  }
}

export default TitleContent
