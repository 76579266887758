import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class ImageBanner extends Component {

  render() {
    const { image } = this.props

    //<GatsbyImage image={getImage(image?.localFile)}  placeholder={'none'} />
    return (
      <section className='image-banner'>
        <img src={image?.localFile?.publicURL} alt={image.altText} />
      </section>
    )
  }
}

export default ImageBanner
