import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'

class Accordion extends Component {

  state = {
    accordionItems: new Array(2).fill(false),
    margin: 0,
  }

  componentDidMount() {
    setTimeout(() => {
      this.resize()
    }, 610)
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    let rect = this.refs.parent.getBoundingClientRect()
    let diff = window.innerHeight - rect.top - this.refs.parent.offsetHeight
    if (diff > 0) this.setState({ margin: diff })
  }

  toggleItem(i) {
    let { accordionItems } = this.state
    accordionItems[i] = !accordionItems[i]
    this.setState({ accordionItems })

    this.refs[`acc${i}`].style.height = accordionItems[i] ? this.refs[`acc${i}`].firstChild.clientHeight + 25 + 'px' : ''
    if (accordionItems[i]) {
      setTimeout(() => {
        this.refs[`acc${i}`].scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
      }, 300)
    }
  }

  render() {
    const { items } = this.props
    let { accordionItems, margin } = this.state

    return (
      <section className='accordion' style={{marginTop: margin}} ref='parent'>
        <div className='accordion__inner'>
          { items && items.map((item, i) => (
            <div className={`accordion__item ${accordionItems[i] ? 'active' : ''}`} onClick={() => this.toggleItem(i)} key={i} ref={`acc${i}`}>
              <div>
                <section className='image-banner'>
                  { item.image && <GatsbyImage image={getImage(item.image?.localFile)} alt={item.image?.altText} /> }
                  { item.title && <h5 className='accordion__title' style={{ color: item.titleColour }}>{item.title}</h5> }
                </section>
                <div className='accordion__content'>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  { item.careers.positions && item.careers.positions.length > 0 && 
                    <div className='accordion__careers'>
                      <h5>{item.careers.title}</h5>
                      <ul>
                      { item.careers.positions.map((position, x) => (
                        <li key={x}>
                          <Link to={`/job/${position.slug}`}>
                            {position.title} 
                            <span>(more...)</span>
                          </Link>
                        </li>
                      )) }
                      </ul>
                    </div>
                  }
                </div>
              </div>
            </div>
          )) }
        </div>
      </section>
    )
  }
}

export default Accordion
