import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ScrollHorizontal from './scroll-horizontal'
import Link from '../utils/link'
import { withBreakpoints } from 'gatsby-plugin-breakpoints'

class LiImage extends Component {

  state = {
    loading: true,
  }

  componentDidMount() {
    let li = new Image()
    li.onload = () => {
      this.setState({ loading: false })
    }
    li.src = this.props.desktop.publicURL
  }

  render() {
    let { loading } = this.state
    const { desktop, altText, active } = this.props
    if (desktop.publicURL.indexOf('.mp4') !== -1) {
      return (
        <div style={{background: `rgba(${desktop.color?.r},${desktop.color?.g},${desktop.color?.b},1)`}}>
          <video
            src={desktop.publicURL}
            alt={altText}
            autoPlay loop muted={!active} playsInline
          />
        </div>
      )
    }

    return (
      <div style={{background: `rgba(${desktop.color?.r},${desktop.color?.g},${desktop.color?.b},1)`}}>
        <img
          src={desktop.publicURL}
          alt={altText}
          width={desktop.childImageSharp?.original?.width}
          height={desktop.childImageSharp?.original?.height}
          className={`${loading ? 'loading' : '' }`}
        />
      </div>
    )
  }
}

class Lightbox extends Component {

  state = {
    info: false,
    animValues: 0,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  toggleLightbox = () => {
    this.props.set(!this.props.active)
  }

  toggleInfo = () => {
    this.setState({ info: !this.state.info })
  }

  onKeyDown = (e) => {
    if ( !this.props.active ) return e
    if (e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40) {
      return e
    }
    let el = document.querySelector('.projects__lightbox__slider')
    let direction = e.keyCode === 39 || e.keyCode === 40 ? 1 : -1
    let dist = parseInt(el.parentElement.dataset.scrolled) + (window.innerWidth * direction)
    if (dist >= el.offsetWidth) {
      dist = el.offsetWidth
    }
    if (dist < 0) {
      dist = 0
    }
    this.setState({ animValues: dist * -1 })
  }

  render() {
    let { info } = this.state
    const { projects, index, active, breakpoints } = this.props

    if (projects?.length === 0) return null

    const project = projects[index]

    const desktopImages = project?.acf?.gallery?.map((el, i) => {
      // <GatsbyImage image={getImage(el?.desktop)} alt={el.altText} loading='eager' />
      return (<LiImage key={i} active={active} {...el} />)
    })
    const mobileImages = project?.acf?.gallery?.map((el, i) => {
      if (el.mobile?.publicURL.indexOf('.mp4') !== -1) {
        return (
          <div key={i}>
            <video
              src={el.mobile.publicURL}
              alt={el.altText}
              autoPlay loop muted={!active} playsInline
            />
          </div>
        )
      }
      return (
        <div key={i}>
          <GatsbyImage image={getImage(el?.mobile)} alt={el.altText} loading='eager' />
        </div>
      )
    })

    const props = {
      style: { color: project.acf.homepageTitleColor || '#ffffff' }
    }

    return (
      <div className={`projects__lightbox ${active ? 'projects__lightbox--active' : ''}`}>

        { active && !breakpoints.large &&
          <ScrollHorizontal animValues={this.state.animValues}>
            <div className='projects__lightbox__slider'>
              { desktopImages }
            </div>
          </ScrollHorizontal>
        }

        { active && breakpoints.large &&
          <div className='projects__lightbox__mobile'>
            { mobileImages }
          </div>
        }

        <div className={`projects__lightbox__info ${info ? 'projects__lightbox__info--active' : ''}`}>
          <div className='projects__lightbox__info__inner'>
            <h2 onClick={this.toggleInfo} {...props}>{ project.title }</h2>
            <div className='projects__lightbox__content' dangerouslySetInnerHTML={{__html: project.content }} {...props} />
            <div className='projects__lightbox__lists'>
              { project?.acf?.services?.length > 0 &&
                <div>
                  <h3 {...props}>Services</h3>
                  <ul>
                    { project.acf.services.map((el, i) => (<li key={i} {...props}>{ el.text }</li>)) }
                  </ul>
                </div>
              }
              { project?.acf?.credits?.length > 0 &&
                <div>
                  <h3 {...props}>Credits</h3>
                  <ul>
                    { project.acf.credits.map((el, i) => (<li key={i}><Link to={el.link} {...props}>{ el.text }</Link></li>)) }
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>

        <button className={`info ${info ? 'hide' : ''}`} type='button' onClick={this.toggleInfo} {...props}>Info</button>
        <button className='close' type='button' onClick={info ? this.toggleInfo : this.toggleLightbox} {...props}>Close</button>

      </div>
    )
  }
}

export default withBreakpoints(Lightbox)
