import React, { Component } from 'react'
import Link from '../utils/link'

class Clients extends Component {

  render() {
    const { clients } = this.props

    return (
      <section className='title-content'>
        <div className='title-content__inner'>
          <div className='clients'>
            { clients && clients.map((el, i) => (
              <div className='clients__item' key={i}>
                <Link to={el.link}>
                  <img src={el.logo.localFile.publicURL} alt={el.logo.altText} />
                </Link>
              </div>
            )) }
          </div>
        </div>
      </section>
    )
  }
}

export default Clients
